import axios, { AxiosInstance } from 'axios';
import qs from 'qs';

interface IEquitesAxiosInstanceOptions {
  headers?: Record<string, string>;
  timeout?: number;
}

export class EquitesAxiosInstance {
  static createInstance(apiUrl: string, options?: IEquitesAxiosInstanceOptions): AxiosInstance {
    return axios.create({
      baseURL: apiUrl,
      headers: { ...options?.headers },
      paramsSerializer: {
        encode: params => qs.parse(params),
        serialize: params => qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
      },
      timeout: options?.timeout ?? 30000,
    });
  }
}
